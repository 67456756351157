<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Schwerpunkte</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center text-light shadow-blue bg-blue">
        <img height="170" width="170" src="assets/images/service-1.png" alt="Image Desing" />
        <h3 class="mb-3 mt-0">Design</h3>
        <p class="mb-0">Erstellung von Dokumentvorlagen, Cooperate Designs, Prototypen und vielem mehr unter Beachtung von UX Richtlinien.</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>
  
    
    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center shadow-yellow bg-yellow">
        <img height="170" width="170" src="assets/images/service-2.png" alt="Image Webentwicklung" />
        <h3 class="mb-3 mt-0">Web Entwicklung</h3>
        <p class="mb-0">Entwicklung moderner Websites und Webanwendung mittels Laravel, Angular, Bootstrap, TS, Sass und vielem mehr.</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>
    
    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center text-light shadow-pink bg-pink">
        <img height="170" width="170" src="assets/images/service-3.png" alt="Image Foto- und Videografie" />
        <h3 class="mb-3 mt-0">Foto- und Videografie</h3>
        <p class="mb-0">Ob Hochzeit, Profilbilder oder Veranstaltungsfotos. Mit Erfahrung und top Ausstattung schaffen wir unvergessliche Erinnerungen.</p>
      </div>
    </div>
    
  </div>

  <div class="mt-5 text-center">
    <p class="mb-0">Ich habe dein Interesse geweckt? <a href="javascript:" (click)="scrollTo('contact')">Klicke hier </a> um Kontakt zu mir aufzunehmen! 👋</p>
  </div>

</div>
