import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DatenschutzComponent } from './components/sections/datenschutz/datenschutz.component';
import { ImpressumComponent } from './components/sections/impressum/impressum.component';
import { LayoutComponent } from './theme/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/theme/view',
    pathMatch: 'full'
  },
  {
    path: '/theme/view',
    redirectTo: 'https://moriz-buehler.de',
    pathMatch: 'full'
  },
  {
    path: 'theme',
    component: LayoutComponent,
    children: [
      {
        path: 'view',
        loadChildren: () => import('./view/view.module').then(module => module.ViewModule)
      }
    ]
  },
  { path: 'theme/datenschutz', component: DatenschutzComponent },
  { path: 'theme/impressum', component: ImpressumComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

