<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Kunden</h2>

  <div class="spacer-60"></div>
  <div class="row">
    <div class="col-md-2 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img src="assets/images/kunden/marini.png" alt="marini-Modeatelier" />

        </div>
      </div>
    </div>
    <div class="col-md-2 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img src="assets/images/kunden/imar.png" alt="imar-lagertechnik-ProSafeLine" />
        </div>
      </div>
    </div>
    <div class="col-md-2 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <a href="https://eventhaus-boger.de/"><img src="assets/images/kunden/boger.png"  alt="eventhaus-boger" /></a>

        </div>
      </div>
    </div>
    <div class="col-md-2 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <a href="https://musikschule-piepmatz.de/"><img src="assets/images/kunden/piepmatz.png"  alt="Musikschule piepmatz" /></a>

        </div>
      </div>
    </div>
    <div class="col-md-2 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img src="assets/images/kunden/gig.png" alt="global invenstment group" />

        </div>
      </div>
    </div>
    <div class="col-md-2 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <a href="https://gregore-bikes.de/"><img src="assets/images/kunden/gregore.png"  alt="gregore-bikes" /></a>

        </div>
      </div>
    </div>
</div>
