import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss']
})
export class WorksComponent implements OnInit {
  public workFilter: string;
  public singleGallery1: any;
  public albums: any = [];

  videoFilmprojekt: Array<object> = [
    {
      video: 'https://www.youtube.com/watch?v=cOTL7EmWWoI?t=14',
      posterImage: 'assets/images/works/filmprojekt_1.jpg',
    }
  ];

  videoAnimation: Array<object> = [
    {
      video: 'https://www.youtube.com/watch?v=yQtjaDXc_Ps',
      posterImage: 'assets/images/works/phone.jpg',
    }
  ];

  constructor() {
    this.workFilter = 'all';
    for (let i = 5; i >= 1; i--) {
      const album = {
        src: 'assets/images/works/' + i + '.svg',
        caption: 'Image ' + i + ' caption here',
        thumb: 'assets/images/works/' + i + '.svg',
      };

      this.albums.push(album);
    }
  }

  ngOnInit(): void {
  }

  onChange (e) {
    this.workFilter = e.target.value;
  }

  showMore(){
    document.getElementById("lutopa").style.display = "block";
    document.getElementById("marini").style.display = "block";
    document.getElementById("btnLoadMore").style.display = "none";
    document.getElementById("btnLoadLess").style.display = "block";
  }
  showLess(){
    document.getElementById("lutopa").style.display = "none";
    document.getElementById("marini").style.display = "none";
    document.getElementById("btnLoadLess").style.display = "none";
    document.getElementById("btnLoadMore").style.display = "block";

  }

}
