<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Aktuelle Projekte</h2>

  <div class="spacer-60"></div>

  <!-- portfolio filter (desktop) -->
  <ul class="portfolio-filter list-inline wow fadeInUp">
    <li class="list-inline-item" [ngClass]="{'current': workFilter === 'all'}" (click)="workFilter = 'all'">Alle Projekte</li>
    <li class="list-inline-item" [ngClass]="{'current': workFilter === 'webEntwicklung'}" (click)="workFilter = 'webEntwicklung'">Web Entwicklung</li>
    <li class="list-inline-item" [ngClass]="{'current': workFilter === 'design'}" (click)="workFilter = 'design'">Design</li>
    <li class="list-inline-item" [ngClass]="{'current': workFilter === 'fotoVideo'}" (click)="workFilter = 'fotoVideo'">Foto & Video</li>
  </ul>

  <!-- portfolio filter (mobile) -->
  <div class="pf-filter-wrapper">
    <select class="portfolio-filter-mobile" (change)="onChange($event)">
      <option value="all">Alle Projekte</option>
      <option value="webEntwicklung">Web Entwicklung</option>
      <option value="design">Design</option>
      <option value="fotoVideo">Foto & Video</option>
    </select>
  </div>

  <!-- portolio wrapper -->
  <div class="row portfolio-wrapper">

    <!-- portfolio item Boger -->
    <div class="col-md-4 col-sm-6 grid-item webEntwicklung" *ngIf="workFilter === 'all' || workFilter === 'webEntwicklung'">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="bogerModalCenter.show()">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Web Entwicklung</span>
            <h4 class="title">Unternehmenswebseite Eventhaus Boger</h4>
            <span class="more-button"><i class="icon-options"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/works/boger_1.jpg" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <app-ui-modal #bogerModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
      <div class="app-modal-body">
        <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
          <img src="assets/images/works/boger_2.jpg" alt="Title" />
          <h2>Eventhaus Boger</h2>
          <p>Die <a href="https://www.tanzschule-boger.de/">Tanzschule Boger</a> in Adelsheim lässt sich als Eventlocation mieten. Hierfür wurde eine moderne Webseite benötigt.</p>
          <p>In Zusammenarbeit mit <a href="https://agbere.com/">Bassit Agbere</a> habe ich diese Webseite entwickelt. Leistungen waren dabei: Eine Zielgruppenanalyse, mehrere Prototypen, eine Individuelle Umsetzung der Webseite sowie die Einbindung des CMS Wordpress.</p>
          <a href="https://eventhaus-boger.de/" class="btn btn-default">Hier geht's zur Webseite</a>
        </div>
      </div>
    </app-ui-modal>

        <!-- portfolio item Piepmatz -->
        <div class="col-md-4 col-sm-6 grid-item webEntwicklung" *ngIf="workFilter === 'all' || workFilter === 'webEntwicklung'">
          <a href="javascript:" class="work-content" data-toggle="modal" (click)="piepmatzModalCenter.show()">
            <div class="portfolio-item rounded shadow-dark">
              <div class="details">
                <span class="term">Web Entwicklung</span>
                <h4 class="title">Unternehmenswebseite Musikschule Piepmatz</h4>
                <span class="more-button"><i class="icon-options"></i></span>
              </div>
              <div class="thumb">
                <img src="assets/images/works/piepmatz_mobile.jpg" alt="Portfolio-title" />
                <div class="mask"></div>
              </div>
            </div>
          </a>
        </div>
    
        <app-ui-modal #piepmatzModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
          <div class="app-modal-body">
            <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
              <img src="assets/images/works/piepmatz_pc.jpg" alt="Title" />
              <h2>Musikschule Piepmatz</h2>
              <p>Für die <a href="https://www.musikschule-piepmatz.de/">Musikschule Piepmatz</a> in Ravensburg habe ich eine neue Webseite sowie neue Bilder für die Webseite entwickelt</p>
              <p>Vorgaben für die Entwicklung der Webseite war dabei, dass der Kunde selbst Inhalte auf der Webseite hinzufügen kann. Hierfür wurde das CMS Wordpress eingesetzt. Darüber hinaus sollte die relativ komplexe Struktur der Webseite übernommen und umgesetzt werden.</p>
              <a href="https://www.musikschule-piepmatz.de/" class="btn btn-default">Hier geht's zur Webseite</a>
            </div>
          </div>
        </app-ui-modal>

            <!-- portfolio item Gregore-bikes -->
    <div class="col-md-4 col-sm-6 grid-item webEntwicklung" *ngIf="workFilter === 'all' || workFilter === 'webEntwicklung'">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="gregoreModalCenter.show()">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Web Entwicklung</span>
            <h4 class="title">Unternehmenswebseite GregorE-Bikes</h4>
            <span class="more-button"><i class="icon-options"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/works/gregore_mobile.jpg" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <app-ui-modal #gregoreModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
      <div class="app-modal-body">
        <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
          <img src="assets/images/works/gregory_pc.jpg" alt="Title" />
          <h2>GregorE-Bikes</h2>
          <p>Für den Fahrradverleih <a href="https://gregore-bikes.de/">GregorE-Bikes</a> in Konstanz sollte eine kleine Unternehmenswebseite entwickelt werden.</p>
          <p>Bei der Entwicklung dieser Webseite sollte mit möglichst wenigen Ressourcen gearbeitet werden. Dadurch sowie durch die einfache Kommunikation mit dem Kunden war es möglich die Seite in weniger als 15 Arbeitsstunden umzusetzen. Neben einer einfachen Seite für den Fahrradverleih und seine Ebikes aus Konstanz hat der Kunde ein entsprechendes Hosting, sowie ein Emailpostfach erhalten.</p>
          <a href="https://gregore-bikes.de/" class="btn btn-default">Hier geht's zur Webseite</a>
        </div>
      </div>
    </app-ui-modal>

    <!-- portfolio item vea -->
    <div class="col-md-4 col-sm-6 grid-item webEntwicklung" *ngIf="workFilter === 'all' || workFilter === 'webEntwicklung'">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="vea.show()">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Web Entwicklung</span>
            <h4 class="title">Digitale Eventplattform</h4>
            <span class="more-button"><i class="icon-options"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/works/MacBookPro.jpg" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <app-ui-modal #vea dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
      <div class="app-modal-body">
        <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
          <img src="assets/images/works/multidevices-2.jpg" alt="Title" />
          <h2>Verkaufsplattform für Tickets mit Unterstützung für NFC und QR-Codes</h2>
          <p>Verwendete Technologien:</p>
        <ul>
          <li class="list-inline-item"><i class="fab fa-html5"></i></li>
          <li class="list-inline-item"><i class="fab fa-css3"></i></li>
          <li class="list-inline-item"><i class="fab fa-js-square"></i></li>
          <li class="list-inline-item"><i class="fab fa-python"></i></li>
        </ul>
          <p>In meinem fünften Semester an der <a href="https://www.mosbach.dhbw.de/">DHBW in Mosbach</a> habe ich habe ich in Zusammenarbeit mit <a href="https://aj-productions.de/">Adrian Fleischer</a>, Jona Wölfl, Max Scheufler und Paula Karlin die Webanwendung VEA programmiert.</p>
          <p>VEA basiert auf einem einfachen HTML Frontend, sowie einem Flaks / Python Backend.</p>
          <p>VEA bietet eine Vielzahl an Funktionen rund um das Thema Eintrittskarten. Dabei besteht ein CRUD-Zyklus für Events. Zu diesen Events können Tickets gebucht werden. Gebuchte Tickets können entweder mit einem QR-Code ausgedruckt werden, oder auf eine NFC fähige Karte geprägt werden. Für Veranstalter gibt es eine Übersicht über alle Veranstaltungen, Besucher und Buchungen. Auch ein Dashboard, über den aktuellen Status anwesender Gäste einer Veranstaltung ist vorhanden.</p>
          <p>Je nach Art des Tickets kann zu einem Event mittels des QR-Codes oder des NFC Tickets eingecheckt werden.</p>
          <p>Für pandemische Situationen besteht die Möglichkeit eine Liste, der bereits anwesenden Gäste mit genauer Ankunftszeit, sowie einer Kontaktmöglichkeit auszudrucken.</p>
          <a href="http://vea.dhbw.online" class="btn btn-default mt-2">Hier gehts zu VEA</a>

        </div>
      </div>
    </app-ui-modal>

    <!-- portfolio item Medicare -->
    <div class="col-md-4 col-sm-6 grid-item webEntwicklung" *ngIf="workFilter === 'all' || workFilter === 'webEntwicklung'">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="medicareModalCenter.show()">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Web Entwicklung</span>
            <h4 class="title">Progressive Web App Medicare</h4>
            <span class="more-button"><i class="icon-options"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/works/medicare_1.jpg" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <app-ui-modal #medicareModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
      <div class="app-modal-body">
        <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
          <img src="assets/images/works/medicare_2.jpg" alt="Title" />
          <h2>Medicare</h2>
          <p>Verwendete Programme:</p>
          <ul>
            <li class="list-inline-item"><i class="fab fa-html5"></i></li>
            <li class="list-inline-item"><i class="fab fa-css3"></i></li>
            <li class="list-inline-item"><i class="fab fa-js-square"></i></li>
            <li class="list-inline-item"><i class="fab fa-angular"></i></li>
            <li class="list-inline-item"><i class="fab fa-node-js"></i></li>
            <li class="list-inline-item"><i class="fab fa-fire"></i></li>
            <li class="list-inline-item"><i class="fab fa-github"></i></li>
          </ul>
          <p>In meinem vierten Semester an der <a href="https://www.mosbach.dhbw.de/">DHBW in Mosbach</a> habe ich in Zusammenarbeit mit <a href="https://aj-productions.de/">Adrian Fleischer</a>, Jona Wölfl und Paula Karlin die Progressive Web App MediCare programmiert.</p>
          <p>Dabei handelt es sich um ein Planer zum verwalten von einzunehmenden Medikamenten. Diese werden sowohl in einer Liste, als auch in einem Kalender dargestellt. Die Anwendung besitzt eine Vielzahl an Funktionen. Vom Anlegen neuer Medikamente bis zu Hinweisen, wenn die Einnahme eines Medikaments überfällig ist. MediCare lässt keine Wünsche öffen.</p>
          <p>Das Highlight der PWA sind natürlich deren typsche Eigenschaften. So lässt sich die Anwendung im Browser verwenden, aber auch auf allen Betriebssystemen installieren und offline verwenden.</p>
          <a href="https://medicare2022.web.app/" class="btn btn-default">Hier geht's zur WebApp</a>
        </div>
      </div>
    </app-ui-modal>

    <!-- portfolio item 3d animation-->
    <div class="col-md-4 col-sm-6 grid-item fotoVideo design" *ngIf="workFilter === 'all' || workFilter === 'fotoVideo' || workFilter === 'design'">
      <a href="javascript:" class="work-video">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Foto & Video</span>
            <h4 class="title">3d Animation</h4>
            <span class="more-button"><i class="icon-camrecorder"></i></span>
          </div>
          <div class="thumb">
            <ng-image-slider [images]="videoAnimation" #nav></ng-image-slider>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

      <!-- portfolio item porsche -->
      <div class="col-md-4 col-sm-6 grid-item design" *ngIf="workFilter === 'all' || workFilter === 'design'">
        <a href="javascript:" class="work-content" data-toggle="modal" (click)="porsche911.show()">
          <div class="portfolio-item rounded shadow-dark">
            <div class="details">
              <span class="term">Design</span>
              <h4 class="title">Modellierung eines 3d Models</h4>
              <span class="more-button"><i class="icon-options"></i></span>
            </div>
            <div class="thumb">
              <img src="assets/images/works/porsche_single.jpg" alt="Portfolio-title" />
              <div class="mask"></div>
            </div>
          </div>
        </a>
      </div>
  
      <app-ui-modal #porsche911 dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
        <div class="app-modal-body">
          <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
            <img src="assets/images/works/porsche_multiple.jpg" alt="Title" />
            <h2>Porsche 911 von 1979</h2>
            <p>In meinem fünften Semester an der <a href="https://www.mosbach.dhbw.de/">DHBW in Mosbach</a> habe ich mit der 3d Grafiksuiute <a href="https://www.blender.org/">"Blender"</a> einen Porsche 911 modeliert.</p>
            <p>Die einzigen hierfür verwendeten Hilfsmittel, waren die orginalen Baupläne von Porsche, sowie Referenzbilder von diversen Verkaufsportalen. Besonders herausfordernd an diesem Projekt waren die vielen runden Formen, welche das Auto aufweist.</p>
          </div>
        </div>
      </app-ui-modal>

     <!-- portfolio item Cibi -->
     <div class="col-md-4 col-sm-6 grid-item design" *ngIf="workFilter === 'all' || workFilter === 'design'">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="cibiModalCenter.show()">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Design</span>
            <h4 class="title">Webdesign Restaurant Cibi in Heidelberg</h4>
            <span class="more-button"><i class="icon-options"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/works/cibi_1.jpg" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <app-ui-modal #cibiModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
      <div class="app-modal-body">
        <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
          <img src="assets/images/works/cibi_2.jpg" alt="Title" />
          <h2>Webdesign Restaurant Cibi</h2>
          <p>Im Rahmen dieses Projekts des dritten Semesters an der <a href="https://www.mosbach.dhbw.de/">DHBW in Mosbach</a> habe ich für einen fiktiven Kunden eine Webseite gestaltet.</p>
          <p>Die Ergebnisse reichen dabei von einem Moodboard, über ein Styletile bishin zu einem High Fidelity Prototypen, welcher auf den beiden Screens abgebildet ist.</p>
        </div>
      </div>
    </app-ui-modal>

    <!-- portfolio item Filmprojekt-->
    <div class="col-md-4 col-sm-6 grid-item fotoVideo" *ngIf="workFilter === 'all' || workFilter === 'fotoVideo'">
      <a href="javascript:" class="work-video">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Foto & Video</span>
            <h4 class="title">Videoprojekt eine Generationsaufgabe</h4>
            <span class="more-button"><i class="icon-camrecorder"></i></span>
          </div>
          <div class="thumb">
            <ng-image-slider [images]="videoFilmprojekt" #nav></ng-image-slider>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

      <!-- portfolio item Twittero -->
      <div class="col-md-4 col-sm-6 grid-item webEntwicklung" *ngIf="workFilter === 'all' || workFilter === 'webEntwicklung'">
        <a href="javascript:" class="work-content" data-toggle="modal" (click)="twitteroModalCenter.show()">
          <div class="portfolio-item rounded shadow-dark">
            <div class="details">
              <span class="term">Web Entwicklung</span>
              <h4 class="title">Twitter Monitoring Software</h4>
              <span class="more-button"><i class="icon-options"></i></span>
            </div>
            <div class="thumb">
              <img src="assets/images/works/twittero_1.jpg" alt="Portfolio-title" />
              <div class="mask"></div>
            </div>
          </div>
        </a>
      </div>
  
      <app-ui-modal #twitteroModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
        <div class="app-modal-body">
          <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
            <img src="assets/images/works/twittero_2.jpg" alt="Title" />
            <h2>Entwicklung einer Minitoring Software für Twitter</h2>
            <p>Verwendete Technologien und Programme:</p>
            <ul>
              <li class="list-inline-item"><i class="fab fa-html5"></i></li>
              <li class="list-inline-item"><i class="fab fa-css3"></i></li>
              <li class="list-inline-item"><i class="fab fa-bootstrap"></i></li>
              <li class="list-inline-item"><i class="fab fa-js-square"></i></li>
              <li class="list-inline-item"><i class="fab fa-php"></i></li>
              <li class="list-inline-item"><i class="fab fa-laravel"></i></li>
              <li class="list-inline-item"><i class="fab fa-docker"></i></li>
              <li class="list-inline-item"><i class="fab fa-github"></i></li>
              <li class="list-inline-item"><i class="fab fa-twitter"></i></li>
            </ul>
            <p>Im Rahmen dieses Projekts meines dritten Semesters an der <a href="https://www.mosbach.dhbw.de/">DHBW in Mosbach</a> habe ich eine Monitoring-Software für Twitter programmiert.</p>
            <p>Diese Software bietet die Möglichkeit Twitter nach aktuellen Tweets zu einem bestimmten Schlagwort zu untersuchen. Dabei kann eingestellt werden, wie viele Tweets angezeigt werden und wie oft die Anzeige aktualisiert wird. Für die Nutzung ist kein Twitter Account notwendig.</p>
            <a href="https://github.com/buehlermoriz/twittero" class="btn btn-default">Jetzt auf Github ansehen</a>
          </div>
        </div>
      </app-ui-modal>

        <!-- portfolio item Taschenrechner -->
        <div class="col-md-4 col-sm-6 grid-item webEntwicklung" *ngIf="workFilter === 'all' || workFilter === 'webEntwicklung'">
          <a href="javascript:" class="work-content" data-toggle="modal" (click)="taschenrechnerModalCenter.show()">
            <div class="portfolio-item rounded shadow-dark">
              <div class="details">
                <span class="term">Web Entwicklung</span>
                <h4 class="title">Programm zum Üben den Grundrechenarten</h4>
                <span class="more-button"><i class="icon-options"></i></span>
              </div>
              <div class="thumb">
                <img src="assets/images/works/taschenrechner_1.jpg" alt="Portfolio-title" />
                <div class="mask"></div>
              </div>
            </div>
          </a>
        </div>
    
        <app-ui-modal #taschenrechnerModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
          <div class="app-modal-body">
            <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
              <img src="assets/images/works/taschenrechner_2.jpg" alt="Title" />
              <h2>Taschenrechner und Grundrechenarten</h2>
              <p>Im Rahmen dieses Projekts meines zweiten Semesters an der <a href="https://www.mosbach.dhbw.de/">DHBW in Mosbach</a> habe ich einen Taschenrechner mit zwei Modi erstellt.</p>
              <p>Dieser ist anders als herkömmliche Taschenrechner nicht nur in der Lage Aufgaben zu lösen. In einem zweiten Modus ist er in der Lage dem Nutzer einfache Rechenaufgaben zu stellen. Dabei kann durch den Nutzer festgelegt werden, in welchem Zahlenraum sich die Aufgaben befinden und welche Rechenoperatoren verwendet werden. Der Taschenrechner gibt Feedback, ob eine Aufgabe richtig gelöst wurde und zählt mit, wie viele Aufgaben richtig und wie viele Aufgaben falsch beantwortet wurden.</p>
              <a href="https://github.com/buehlermoriz/Calculator" class="btn btn-default">Jetzt auf Github ansehen</a>
            </div>
          </div>
        </app-ui-modal>

          <!-- portfolio item Lutopa -->
          
          <div class="col-md-4 col-sm-6 grid-item webEntwicklung" id="lutopa" *ngIf="workFilter === 'all' || workFilter === 'webEntwicklung'">
            <a href="javascript:" class="work-content" data-toggle="modal" (click)="lutopaModalCenter.show()">
              <div class="portfolio-item rounded shadow-dark">
                <div class="details">
                  <span class="term">Web Entwicklung</span>
                  <h4 class="title">dreidimensionales Jump'n Run Computerspiel</h4>
                  <span class="more-button"><i class="icon-options"></i></span>
                </div>
                <div class="thumb">
                  <img src="assets/images/works/lutopa_1.jpg" alt="Portfolio-title" />
                  <div class="mask"></div>
                </div>
              </div>
            </a>
          </div>
      
          <app-ui-modal #lutopaModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
            <div class="app-modal-body">
              <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
                <img src="assets/images/works/lutopa_2.jpg" alt="Title" />
                <h2>Lutopa - ein 3d Computerspiel</h2>
                <p>Bei Lutopa handelt es sich um eines der eindrucksvollsten Projekte meines Stduiums. Im Rahmen dieses Projekts haben Jona Wölfl, <a href="https://agbere.com/">Bassit Agbere</a>, <a href="https://aj-productions.de/">Adrian Fleischer</a> und ich mit C# und der Game Engine Unity ein spannendes Abenteuer für PC und Mac erstellt.</p>
                <p>Auf drei unterschiedlichen Welten führen wir den Spieler durch eine abenteuerliche Geschichte. Dabei sammelt er in einer Welt voller fliegenden Inseln die Teile seines zerstörten Flugzeugs um dieses zu reparieren.</p>
                <p>Unter den Folgenden Links kannst du das Spiel herunterladen und kostenlos sowie webefrei spielen. Viel Spaß!</p>
                <a href="https://app.box.com/s/i5p5l45xbq6s4zy8j2g54q9iw5nrmrrh" class="btn btn-default mt-2">Download Mac</a>
                <a href="https://app.box.com/s/gcnj4bhy0b6l595p8hhfscdo44jzowtv" class="btn btn-default mt-2">Download Windows</a>

              </div>
            </div>
          </app-ui-modal>
        
        <!-- portfolio item marini -->
          <div class="col-md-4 col-sm-6 grid-item webEntwicklung hideStart" id="marini" *ngIf="workFilter === 'all' || workFilter === 'webEntwicklung'">
            <a href="javascript:" class="work-content" data-toggle="modal" (click)="mariniModalCenter.show()">
              <div class="portfolio-item rounded shadow-dark">
                <div class="details">
                  <span class="term">Web Entwicklung</span>
                  <h4 class="title">Unternehmenswebseite Marini Modeatelier</h4>
                  <span class="more-button"><i class="icon-options"></i></span>
                </div>
                <div class="thumb">
                  <img src="assets/images/works/marini_1.jpg" alt="Portfolio-title" />
                  <div class="mask"></div>
                </div>
              </div>
            </a>
          </div>
      
          <app-ui-modal #mariniModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
            <div class="app-modal-body">
              <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
                <img src="assets/images/works/marini_2.jpg" alt="Title" />
                <h2>Unternehmenswebseite Marini Modeatelier</h2>
                <p>In diesem Projekt habe ich eine Webseite für das Marini Modeatelier in Ravensburg erstellt. Dabei war es wichtig, dass die Webseite ein einfaches CMS besitzt, über welches die Inhaberin des Modeateliers in Ravensburg die Webseite nach deren Erstellung verwalten kann.</p>
                <p>Für den Einstieg in die Webseite wurde ein  Video gedreht, welches die Highlights des Ravensburger Modeateliers darstellt.</p>
                <!-- <a href="https://www.marinimodeatelier.de/" class="btn btn-default">Hier geht's zur Webseite</a> -->
              </div>
            </div>
          </app-ui-modal>
  </div>

  <!-- more button -->
  <div class="load-more text-center mt-4">
    <a href="javascript:" (click)="showMore()" id="btnLoadMore" class="btn btn-default"><i class="fas fa-spinner"></i> mehr anzeigen</a>
    <a href="javascript:" (click)="showLess()" id="btnLoadLess" class="btn btn-default"><i class="fas fa-spinner"></i> weniger anzeigen</a>

    <!-- numbered pagination (hidden for infinite scroll) -->
    <ul class="portfolio-pagination list-inline d-none">
      <li class="list-inline-item">1</li>
      <li class="list-inline-item"><a href="javascript:">2</a></li>
    </ul>
  </div>

</div>
