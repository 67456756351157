<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Werdegang</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col">

      <!-- timeline wrapper -->
      <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">

            <!-- timeline item -->
            <div class="timeline-container wow fadeInUp">
              <div class="content">
                <span class="time">2021 - heute</span>
                <h3 class="title">Kleinunternehmer</h3>
                <p>Seit 2021 arbeite ich neben meinem Studium selbständig an eigenen Projekten. Dabei ligt der Fokus auf Webseiten für kleine Unternehmen der Region. Gerne kannst du Kontakt zu mir aufnehmen und wir besprechen dein Projekt.</p>
              </div>
            </div>
    
         <!-- timeline item -->
         <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2020 - heute</span>
            <h3 class="title">Studium Onlinemedien</h3>
            <p>Seit 2020 Studiere ich Onlinemedien an der <a href="https://www.mosbach.dhbw.de/"> DHBW in Mosbach</a>
              und bei <a href="https://www.schwaebisch-media.de/">Schwäbisch Media</a> in Ravensburg.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2017 - 2020</span>
            <h3 class="title">Abitur</h3>
            <p>In den Jahren 2017 bis 2020 habe ich am katholischen Gymnasium St. Konrad in Ravensburg  erfolgreich mein algemeines Abitur abgelegt.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">2016 - 2017</span>
            <h3 class="title">Ausbildung Orthopädietechnik</h3>
            <p>In den Jahren 2016 und 2017 habe ich eine Ausbildung als Orthopädie-Technik Mechaniker im Sanitätshaus Trapp in Friedrichshafen begonnen. Nach einem 3/4 Jahr habe ich entschieden meine Leidenschaft zum Beruf zu machen und in die IT zu wechseln.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
          <div class="content">
            <span class="time">2006 - 2016</span>
            <h3 class="title">Realschulabschluss</h3>
            <p>In den Jahren 2006 bis 2013 habe ich erfolgreich an der <a href="https://www.freieschulelindau.de/">Freien Schule in Lindau</a>
              meinen Realschulabschluss (mittlere Reife) abgelegt. Hierbei lag der Fokus auf innovativen Lernmethoden wie Montessori und Freinet.</p>
          </div>
        </div>

        <!-- main line -->
        <span class="line"></span>

      </div>

    </div>


</div>
