<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Über mich</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img src="assets/images/avatar-2.png" style="height: 150px; width: 150px;"alt="Bolby" />
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>
    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div class="col-md-6">
            <!-- about text -->
            <p>Hey, ich bin Moriz! Ich studiere Onlinemedien an der <a href="https://www.mosbach.dhbw.de/"> DHBW in Mosbach</a>
              und bei <a href="https://www.schwaebisch-media.de/">Schwäbisch Media</a> in Ravensburg. In meiner Freizeit arbeite ich als Kleinunternehmer an digitalen Projekten. Das nächste könnte dein Projekt sein!</p>
            <div class="mt-3">
              <a href="javascript:" class="btn btn-default" (click)="scrollTo('contact')">jetzt beauftragen</a>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
          </div>
          <div class="col-md-6">
            <!-- tech item -->
            <div class="tech-item">
              <div class="tech-info clearfix">
                <h4 class="float-left mb-3 mt-0">Web Entwicklung</h4>
                <span class="float-right">90%</span>
              </div>
              <ngb-progressbar role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" type="warning" [value]="95"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- tech item -->
            <div class="tech-item">
              <div class="tech-info clearfix">
                <h4 class="float-left mb-3 mt-0">Data Engineering</h4>
                <span class="float-right">85%</span>
              </div>
              <ngb-progressbar role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" type="danger" [value]="85"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- tech item -->
            <div class="tech-item">
              <div class="tech-info clearfix">
                <h4 class="float-left mb-3 mt-0">Design</h4>
                <span class="float-right">65%</span>
              </div>
              <ngb-progressbar role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" type="primary" [value]="70"></ngb-progressbar>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- row end -->

  <div class="spacer-70"></div>
  
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Technologien</h2>
  <div class="spacer-60"></div>
  <div class="row">
    
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/html5.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/css3.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/sass.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/prettier.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/bootstrap.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/javascript.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/typescript.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/csharp.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/java.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/php.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/react.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/angular.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/laravel.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/nodedotjs.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/cakephp.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/jquery.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/json.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/pwa.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/mariadb.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/mongodb.svg"  alt="client-name" />
      </div>
    </div>
    <div class="col-md-2 col-4 d-flex justify-content-center">
      <div class="tech-item">
        <img class="icon" src="assets/images/technologien/firebase.svg"  alt="client-name" />
      </div>
    </div>
  </div>
</div>
