<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Jetzt kontaktieren</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-4">
      <!-- contact info -->
      <div class="contact-info">
        <h3 class="wow fadeInUp">Melde dich jetzt unverbindlich!</h3>
        <p class="wow fadeInUp">Du willst dich direkt bei mir Melden? Schreibe doch eine <a href="mailto:buehlermoriz@gmail.com">Email</a>. 👋</p>
      </div>
    </div>

    <div class="col-md-8">
      <!-- Contact Form -->
      <form id="contact-form" class="contact-form mt-6" method="post" (submit)="sendEmail($event)"  action="javascript:">

        <div class="messages"></div>

        <div class="row">
          <div class="column col-md-6">
            <!-- Name input -->
            <div class="form-group">
              <input type="text" class="form-control" name="user_name" id="user_name" placeholder="Dein Name" required="required" data-error="Wie darf ich dich nennen?">
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-6">
            <!-- Email input -->
            <div class="form-group">
              <input type="email" class="form-control" id="InputEmail" name="reply_to" placeholder="Emailadresse" required="required" data-error="Wo kann ich mich bei dir melden?">
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <!-- Betreff input -->
            <div class="form-group">
              <input type="text" class="form-control" id="InputSubject" name="user_subject" placeholder="Betreff" required="required" data-error="Worum geht es?">
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <!-- Message textarea -->
            <div class="form-group">
              <textarea name="message" id="InputMessage" class="form-control" rows="5" placeholder="Nachricht" required="required" data-error="Was ist deine Anfrage?"></textarea>
              <div class="help-block with-errors"></div>
            </div>
          </div>
        </div>
        <div class="g-recaptcha" data-sitekey="6LfpBrIkAAAAABBQzgAb0kbyDm2ZXgWqEHM8dbvN"></div>

        <button type="submit" name="submit" id="submit_form" value="Send" class="btn btn-default">Nachricht senden</button>
      </form>
      <p id="email_worked" class="mt-2">Super! Das hat geklappt. Vielen Dank für deine Email</p>
      <p id="email_error" class="mt-2">Ups das hat leider nicht geklappt. Schreibe mir doch <a href="mailto:buehlermoriz@gmail.com">hier</a> direkt eine Email</p>
    </div>

  </div>

</div>
