<div class="container">
    <div class="spacer-60"></div>

    <!-- section title -->
    <h2 class="section-title wow fadeInUp">Impressum</h2>
  
    <div class="spacer-60"></div>
  
    <div class="row">
  
      <div class="col-md-3">
        <div class="text-center text-md-left">
          <!-- avatar image -->
          <img src="assets/images/avatar-2.png" style="height: 150px; width: 150px;"alt="Avatar" />
        </div>
        <div class="spacer-30 d-md-none d-lg-none"></div>
      </div>
      <div class="col-md-9 triangle-left-md triangle-top-sm">
        <div class="rounded bg-white shadow-dark padding-30">
          <div class="row">
            <div class="col">
              <!-- about text -->
                <p>Hallo und willkommen im Impressum!</p>
                <p>Bei dieser Webseite handelt es sich um ein Template von "© 2020 Bolby Template". Dieses wurde am 23.03.2022 ordnungsgemäß auf <a href="https://elements.envato.com/bolby-personal-portfolio-angular-template-W3JDR7N">Envato Elements</a> im Rahmen eines Abonements gekauft und lizensiert. Falls nötig lasse ich dir gerne die offizielle Lizenz zur Bestätigung zukommen. Melde dich dafür einfach über die folgenden Kontaktmöglichkeiten.</p>
                <p>Weitere Angaben gemäß §5 TMG <br> Moriz Bühler <br> Otto-Timme str. 11 <br> 88214 Ravensburg <br><br> Vertreten durch: <br> Moriz Bühler <br><br>
                Kontakt: <br> Telefon: 0170-5037207 <br> E-Mail: buehlermoriz@gmail.com</p>
                <div class="mt-3">
              </div>
              <div class="spacer-30 d-md-none d-lg-none"></div>
              <div class="mt-4">
                <a href="https://moriz-buehler.web.app/" class="btn btn-default">zurück zur Webseite</a>
              </div>
            </div>
    </div>
    <!-- row end -->