import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  sendEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm('service_dxu3jae', 'template_e0kf9c5', e.target as HTMLFormElement, '9pGtHz9VAvvcWbNk6')
      .then((result: EmailJSResponseStatus) => {
    document.getElementById("email_worked").style.display = "block";
    document.getElementById("submit_form").style.display = "none";
      }, (error) => {
    document.getElementById("email_error").style.display = "block";
        console.error("Error beim Verschicken der Mail: "+error.text);
      });
  }
}
